import React from 'react'
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function Home() {
  const [passwd, setpasswd] = React.useState('')
  const [decryptedData, setdecryptedData] = React.useState('')
  const save = () => { 
    const secretPwCrpt = 'mokuton123'
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(passwd), secretPwCrpt).toString();
      Cookies.set('username', 'mariah')
      Cookies.set('password', encryptedData)
   }

   const getall = () => { 
    const bytes = CryptoJS.AES.decrypt(Cookies.get('password'), 'mokuton123');
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log(data);
 }
  return (
    <div>
      <input onChange={(e)=> setpasswd(e.target.value)} placeholder='Message...'/>
      <button onClick={save}>Send</button>
      <button onClick={getall}>get</button>
    </div>
  )
}

export default Home